import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useMemo, useState, useRef } from "react";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination/Pagination";
import StandardBanner from "../../../components/StandardHero/StandardHero";

import {
  caseStudiesBannerContainer,
  caseStudiesBannerContent,
  caseStudiesContentContainer,
  allCaseStudiesContainer,
  caseStudyContent,
  paginationContainer,
} from "./index.module.scss";
const PageSize = 16;
const CaseStudy = ({
  data: {
    strapiCaseStudies,
    strapiCaseStudies: { subBanner, metaData },
    allArticles: { nodes: allCaseStudies },
  },
}) => {
  const bannerImage =
    strapiCaseStudies.heroBanner.image.localFile.childImageSharp
      .gatsbyImageData;
  const bannerTitle = "";
  const showBreadcrumb = strapiCaseStudies.heroBanner.showBreadcrumb;
  const scrollToRef = useRef();

  const breadcrumbs = [{ name: "CASE STUDIES" }];

  const [currentPage, setCurrentPage] = useState(1);

  const currentCaseStudies = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allCaseStudies.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allCaseStudies]);

  // select only articles with publish date is earlier than now
  const filteredCaseStudies = currentCaseStudies.filter(function (obj) {
    return new Date(obj.publish_at) < Date.now();
  });

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({
      behavior: "smooth",
      top: scrollToRef.current.offsetTop,
    });
  };

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: strapiCaseStudies.heroBanner.image.localFile.publicURL,
        article: false,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <section className={caseStudiesBannerContainer}>
        <img src={subBanner.image.localFile.publicURL} alt="logo" />
        <div className={caseStudiesBannerContent}>
          <h3>Case studies</h3>
        </div>
      </section>

      <section className={caseStudiesContentContainer} ref={scrollToRef}>
        <div className={allCaseStudiesContainer}>
          {filteredCaseStudies.map(
            ({ headingText, mainImage, id, slug }, index) => {
              return (
                <div
                  role="button"
                  key={id}
                  className={caseStudyContent}
                  onClick={() => navigate(`/case-studies/${slug}`)}
                  onKeyDown={(event) => {
                    if (event.keycode === 13) navigate(`/case-studies/${slug}`);
                  }}
                  tabIndex={index}
                >
                  <GatsbyImage
                    image={
                      mainImage?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={mainImage?.alternativeText}
                  />
                  <h4>{headingText}</h4>
                  {/* Temporarily commented out.  to be added later
                  <div className={caseStudyTagsContainer}>
                    <p className="ui">retirement</p>
                    <p className="ui">dws</p>
                  </div> */}
                </div>
              );
            }
          )}
        </div>
        <div className={paginationContainer}>
          <Pagination
            currentPage={currentPage}
            totalCount={filteredCaseStudies.length}
            pageSize={PageSize}
            onPageChange={(page) => handleOnPageChange(page)}
          />
        </div>
      </section>
      <div className={paginationContainer}>
        <Pagination
          currentPage={currentPage}
          totalCount={filteredCaseStudies.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Layout>
  );
};

export default CaseStudy;

export const query = graphql`
  {
    strapiCaseStudies: strapiLatestInsights {
      heroBanner {
        title
        showBreadcrumb
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      subBanner {
        heading
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
      metaData {
        metadescription
        pageTitle
      }
    }
    allArticles: allStrapiCaseStudies(
      sort: { order: DESC, fields: publish_at }
      filter: { mediaType: { in: ["CaseStudy"] } }
    ) {
      nodes {
        id
        published_at
        publish_at
        mediaType
        headingText
        headerCopy
        slug
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 420
                width: 645
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`;
